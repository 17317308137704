<script setup>
import { ref, computed } from 'vue';
import boundingBoxes from '/data/bounding-boxes.json';
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { mapStyles } from "@/stores/ProMapStyles.js";

import { useGroups } from "@/Composables/UseGroups.js";
const groups = useGroups();

import ToggleSwitch from '@/PureComponents/ToggleSwitch.vue';

import { CogIcon, XMarkIcon, ArrowRightIcon, ArrowLeftIcon, LockClosedIcon, CheckIcon } from "@heroicons/vue/24/outline";

const app = appStateStore();
const settings = settingsStore();


settings.filterGroup = true; // always default to filtering the group

groups.loadGroups();

function sortedAndFilteredGroups() {
	if (!groups.groups.value) return [];
	return filteredGroups().sort(function (a, b) {
		if (app.user?.id) {
			return (+b.user_id==app.user?.id) - (+a.user_id==app.user?.id) || a.name.localeCompare(b.name)
		}
		return a.name.localeCompare(b.name);
	});
}

function filteredGroups() {
	if (app.groupSearchText.length==0) return groups.groups.value;

	return groups.groups.value.filter(function (el) {
		return el.name.toLowerCase().indexOf(app.groupSearchText.toLowerCase())!=-1;
	});
}

function openGroup(group) {
	app.groupSlug = group.slug;
	app.show=null;
	settings.sideBarPage = 'list';
}
</script>

<template>

	<div class="flex w-full sm:max-w-[600px] mx-auto overflow-hidden">
		<div class=" flex p-2 sm:py-4 sm:px-8 flex-col w-full overflow-hidden">
			<div class="frosted-panel flex flex-col overflow-hidden">

				<div class="flex items-center px-4 pt-4 pb-2 gap-4">

					<button title="close" class="round-button" @click="app.show='puretrack-menu'">
						<ArrowLeftIcon class="w-6 h-6" />
					</button>

					<h2 class="menu-title flex-grow flex justify-center items-center gap-4">
						{{ $t('group-menu.groups') }}

						<Transition>
							<div class="flex flex-col menu-section-title items-center" v-if="groups.loading.value>0">
								<LoadingHorizontal class="mt-1"></LoadingHorizontal>
							</div>
						</Transition>
					</h2>


					<button title="close" class="round-button" @click="app.show=null">
						<XMarkIcon class="w-6 h-6" />
					</button>

				</div>


				<div class="flex flex-col overflow-y-scroll px-4 pb-4 pt-6 gap-4">

					<div class="menu-section">

						<div class="p-2 flex gap-2 items-center">
							<p class="">Filter List</p>
							<input class="flex-grow" type="text" v-model="app.groupSearchText">
						</div>


						<button v-for="group in sortedAndFilteredGroups()"
							:key="group.id"
							@click="openGroup(group)"
							class="menu-row menu-row-clickable flex items-center cursor-pointer py-1 px-2 justify-between w-full">
							
							{{group.name}}

							<button :title="$t('puretrack-menu.change-mode')" class="round-button">
								<ArrowRightIcon class="w-3 h-3" />
							</button>
						</button>

						<div class="menu-row flex items-center text-center text-gray-500 dark:text-gray-600" v-if="groups.loading.value<=0 && groups==null">
							{{ $t('app.none') }}
						</div>

					</div>
				</div>


			</div>


		</div>
	</div>

</template>