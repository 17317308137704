import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref } from 'vue';

export const pointTypesStore = defineStore('pointTypes', () => {

	function getIconImage(typeId, suffix='_w') {
		for (var i=0; i<pointTypes.length; i++) {
			if (pointTypes[i].id==typeId) return '/images/icons/' + pointTypes[i].icon + suffix + '.svg';
		}
		return '/images/icons/arrow' + suffix + '.svg';
	}

	function getType(id) {
		return pointTypes.find((point) => point.id == id);
	}
	function getTypeByName(name) {
		return pointTypes.find((point) => point.name.toLowerCase() == name.toLowerCase());
	}
	function getCategory(cat) {
		let pointIDs = null;

		if (cat=='activity') pointIDs = getActivity();
		if (cat=='boats') pointIDs = getBoats();

		if (pointIDs!==null) {
			return pointTypes.filter((point) => pointIDs.includes(point.id));
		}

		return pointTypes.filter((point) => point.cat == cat);
	}

	function getBoats() {
		return [36,37,38,39,40,41,42,43,44,45,46,71];
	}
	function getGliders() {
		return [63,1,2,27];
	}
	function getFreeflight() {
		return [63,6,7,17,20];
	}
	
	function getMarine() {
		var results = [];
		for (var i=0; i<pointTypes.length; i++) {
			if (pointTypes[i].cat=='water') results.push(pointTypes[i].id);
		}
		return results;
	}

	function getActivity() {
		return [65,66,67,68,69,70,72,73,74,75,76,77,78];
	}

	function getGA() {
		return [0,1,2,3,4,5,6,7,8,10,11,12,13,14,15,16,17,18,19,48,49,50,58];
	}


	function getCurrentFilterTypes(mode) {
		if (mode=='gliding') return getGliders();
		if (mode=='freeflight') return getFreeflight();
		if (mode=='marine') return getMarine();
		if (mode=='ga') return getGA();
		return null;
	}

	const pointTypes = [
		{id: 0,  featured:false, cat: "other",		name: "Unknown", 			icon: "arrow"},
		{id: 1,  featured:true,  cat: "air",		name: "Glider", 			icon: "glider"},
		{id: 2,  featured:false, cat: "air",		name: "Tow", 				icon: "towplane"},
		{id: 3,  featured:false, cat: "air",		name: "Helicopter", 		icon: "helicopter"},
		{id: 4,  featured:false, cat: "air",		name: "Parachute", 			icon: "paraglider"},
		{id: 5,  featured:false, cat: "air",		name: "Drop Plane", 		icon: "plane"},
		{id: 6,  featured:true, cat: "air",			name: "Hang Glider",		icon: "hangglider"},
		{id: 7,  featured:true, cat: "air",			name: "Paraglider", 		icon: "paraglider"},
		{id: 8,  featured:false, cat: "air",		name: "Plane", 				icon: "plane"},
		{id: 9,  featured:false, cat: "air",		name: "Jet", 				icon: "jet"},
		{id: 10, featured:false, cat: "air",		name: "UFO", 				icon: "ufo"},
		{id: 11, featured:false, cat: "air",		name: "Balloon", 			icon: "balloon"},
		{id: 12, featured:false, cat: "air",		name: "Airship", 			icon: "blimp"},
		{id: 13, featured:false, cat: "air",		name: "Drone",				icon: "drone"},
		{id: 14, featured:false, cat: "other",		name: "Unknown", 			icon: "arrow"},
		{id: 15, featured:false, cat: "other",		name: "Static Object", 		icon: "tower"},
		{id: 16, featured:false, cat: "air",		name: "Gyrocopter", 		icon: "gyro"},
		{id: 17, featured:false, cat: "air",		name: "Paramotor", 			icon: "paraglider"},
		{id: 18, featured:false, cat: "air",		name: "Cropduster", 		icon: "cropduster"},
		{id: 19, featured:false, cat: "air",		name: "Rocket", 			icon: "rocket"},
		{id: 20, featured:false, cat: "other",		name: "Person", 			icon: "person"},
		{id: 21, featured:false, cat: "ground",		name: "Shipping Container",	icon: "container"},
		{id: 22, featured:false, cat: "ground",		name: "Car", 				icon: "arrow"},
		{id: 23, featured:false, cat: "ground",		name: "Van", 				icon: "arrow"},
		{id: 24, featured:false, cat: "ground",		name: "Campervan", 			icon: "arrow"},
		{id: 25, featured:false, cat: "ground",		name: "Caravan", 			icon: "arrow"},
		{id: 26, featured:false, cat: "ground",		name: "Trailer", 			icon: "arrow"},
		{id: 27, featured:false, cat: "ground",		name: "Glider Trailer",		icon: "arrow"},
		{id: 28, featured:false, cat: "ground",		name: "Bicycle", 			icon: "arrow"},
		{id: 29, featured:false, cat: "ground",		name: "Scooter", 			icon: "arrow"},
		{id: 30, featured:false, cat: "ground",		name: "Skateboard", 		icon: "arrow"},
		{id: 31, featured:false, cat: "ground",		name: "Heavy Truck", 		icon: "arrow"},
		{id: 32, featured:false, cat: "ground",		name: "Light Truck", 		icon: "arrow"},
		{id: 33, featured:false, cat: "ground",		name: "Motorbike", 			icon: "arrow"},
		{id: 34, featured:false, cat: "ground",		name: "Train", 				icon: "arrow"},
		{id: 35, featured:false, cat: "ground",		name: "Go-Cart", 			icon: "arrow"},
		{id: 36, featured:false, cat: "water",		name: "Hovercraft",			icon: "boat"},
		{id: 37, featured:false, cat: "water",		name: "Yacht", 				icon: "ship"},
		{id: 38, featured:false, cat: "water",		name: "Fishing Boat",		icon: "fishingboat"},
		{id: 39, featured:false, cat: "water",		name: "Dinghy", 			icon: "dinghy"},
		{id: 40, featured:false, cat: "water",		name: "Boat", 				icon: "boat"},
		{id: 41, featured:false, cat: "water",		name: "Catamaran", 			icon: "catamaran"},
		{id: 42, featured:false, cat: "water",		name: "Houseboat", 			icon: "boat"},
		{id: 43, featured:false, cat: "water",		name: "Trawler", 			icon: "ship"},
		{id: 44, featured:false, cat: "water",		name: "Ship", 				icon: "ship"},
		{id: 45, featured:false, cat: "water",		name: "Canoe", 				icon: "canoe"},
		{id: 46, featured:false, cat: "water",		name: "Kayak", 				icon: "kayak"},
		{id: 47, featured:false, cat: "ground",		name: "Pickup/Ute", 		icon: "arrow"},
		{id: 48, featured:false, cat: "air",		name: "Sea Plane", 			icon: "plane"},
		{id: 49, featured:false, cat: "air",		name: "Amphibian", 			icon: "plane"},
		{id: 50, featured:false, cat: "air",		name: "Tiltrotor", 			icon: "plane"},
		{id: 51, featured:false, cat: "air",		name: "Jet Super", 			icon: "jet"},
		{id: 52, featured:false, cat: "air",		name: "Jet Heavy", 			icon: "jet"},
		{id: 53, featured:false, cat: "air",		name: "Jet Medium",			icon: "jet_m"},
		{id: 54, featured:false, cat: "air",		name: "Jet Light", 			icon: "jet_m"},
		{id: 55, featured:false, cat: "air",		name: "Turboprop Heavy",	icon: "turboprop_m"},
		{id: 56, featured:false, cat: "air",		name: "Turboprop Medium",	icon: "turboprop_m"},
		{id: 57, featured:false, cat: "air",		name: "Turboprop Light",	icon: "turboprop_l"},
		{id: 58, featured:false, cat: "air",		name: "Plane Twin Engine",	icon: "plane"},
		{id: 59, featured:false, cat: "ground",		name: "Horse",				icon: "arrow"},
		{id: 60, featured:false, cat: "water",		name: "Shark",				icon: "arrow"},
		{id: 61, featured:false, cat: "water",		name: "Sail Boat",			icon: "sailboat"},
		{id: 62, featured:false, cat: "water",		name: "Tanker", 			icon: "tanker"},
		{id: 63, featured:false, cat: "other",		name: "Unknown Skylines",	icon: "arrow"},
		{id: 64, featured:false, cat: "ground",		name: "Milk Tanker",		icon: "arrow"},
		{id: 65, featured:false, cat: "other",	name: "Hiker",				icon: "arrow"},
		{id: 66, featured:false, cat: "water",	name: "Swimmer",			icon: "arrow"},
		{id: 67, featured:false, cat: "ground",	name: "Skier",				icon: "arrow"},
		{id: 68, featured:false, cat: "ground",	name: "Mountain Biker",		icon: "arrow"},
		{id: 69, featured:false, cat: "ground",	name: "Biker",				icon: "arrow"},
		{id: 70, featured:false, cat: "ground",	name: "Cyclist",			icon: "arrow"},
		{id: 71, featured:false, cat: "water",	name: "Raft",				icon: "arrow"},
		{id: 72, featured:false, cat: "water",	name: "Kite Surfer",		icon: "arrow"},
		{id: 73, featured:false, cat: "water",	name: "Wind Surfer",		icon: "arrow"},
		{id: 74, featured:false, cat: "water",	name: "Downwind Foiler",	icon: "arrow"},
		{id: 75, featured:false, cat: "water",	name: "Wing Foiler",		icon: "arrow"},
		{id: 76, featured:false, cat: "water",	name: "Surfer",				icon: "arrow"},
		{id: 77, featured:false, cat: "water",	name: "Wind Surfer",		icon: "arrow"},
		{id: 78, featured:false, cat: "water",	name: "Paddle Boarder",		icon: "arrow"},
		];

	return { 
		pointTypes,
		getType,
		getTypeByName,
		getIconImage,
		getGliders,
		getFreeflight,
		getMarine,
		getGA,
		getCategory,
		getCurrentFilterTypes,
		getActivity,
	}

});


// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(pointTypesStore, import.meta.hot))
}

