import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref } from 'vue';
import { useMapTools } from "@/Composables/MapTools.js";
import { get_url_param } from '../Helpers.js';

export const settingsStore = defineStore('settings', () => {

	const mapTools = useMapTools();

	const language = ref('en');
	const mapLat = ref(-40.97435);
	const mapLong = ref(175.409);
	const mapZoom = ref(5);
	const altitudeUnits = ref("feet");
	const speedUnits = ref("knots");
	const verticalUnits = ref("knots");
	const distanceUnits = ref("kilometers");
	const timeUnits = ref("12"); // 12 or 24
	const timezoneUnits = ref("local"); // user or local
	const baseMapID = ref('terrain');
	const freeMapID = ref('voyager');

	const showIcons = ref(true);
	const showSelectedTrack = ref(true);
	const showLabels = ref(true);
	const showSecondLine = ref(true);
	const showWobbleLabels = ref(true);
	const maxWobbleLabels = ref(120);
	const wobbleLableSize = ref(14);
	const wobbleLableSizeSecondLine = ref(12);
	const showOnGroundLabels = ref(true);

	const showHeightLabel = ref(true);
	const showAGLLabel = ref(false);
	const showSpeedLabel = ref(false);
	const showVSpeedLabel = ref(false);
	const showThermalClimbLabel = ref(false);
	const showNameLabel = ref(false);
	const showClassLabel = ref(false);
	const showSourceLabel = ref(false);
	const showTargetDistanceLabel = ref(false);
	const showTargetBearingLabel = ref(false);
	const showRegoLabel = ref(false);
	const showCallsignLabel = ref(false);
	const showAgeLabel = ref(false);

	const mainLabelAircraft = ref('rego'); // show rego or callsign

	const showCowFarts = ref(false);

	const showAircraftSearch = ref(false);

	const sortBy = ref("label");
	const sortOrder = ref("desc");
	const selectedAtTop = ref(false);

	const showLegend = ref(true);
	const following = ref([]);
	const followSingle = ref(null);
	const pauseFollowing = ref(true);
	const followSelected = ref(true);
	const onlyShowSelected = ref(false);
	const legendHideUnknown = ref(true);
	const legendShowThermalClimbRate = ref(true);
	const showTails = ref(true);

	// chart options
	const altitudeChartSize = ref(150);
	const showChart = ref(true);
	const loadOtherMapIconsWhileBrowsing = ref(true);


	const showInfo = ref(false);
	const showQuickButtons = ref(true);
	const showPicture = ref(false);
	const showShare = ref(false);
	const showSource = ref(false);

	const filterMaxAge = ref(30);
	const filterMaxAltitude = ref(null);

	const shortenAircraftRegistrations = ref(true);
	const showUnidentifiedLabels = ref(false);

	const contestID = ref(null);
	const showContestPanel = ref(true);

	const mode = ref("all"); // all, gliders or freeflight etc

	const yAxis = ref('altitude'); // or speed
	const trackLength = ref(60*6);
	const multiTracksLength = ref(60);
	const showDebug = ref(false);
	const showAboveGroundLevel = ref(0);

	const simpleMode = ref(false);
	const showAirspace = ref(false);
	const showThermals = ref(false);
	const showWeatherStations = ref(false);
	const showThermalHeights = ref(false);
	const showZones = ref(false);
	const showRainRadar = ref(false);

	const showLocations = ref(false);
	const showAirports = ref(true);
	const showSmallAirports = ref(true);
	const showHelipads = ref(true);
	const showLaunchSites = ref(true);

	const maxThermalAltitude = ref(3048);

	const filterGroup = ref(true);
	const followGroup = ref(true);
	const loadAllGroup = ref(true);
	const filterComp = ref(true);
	const darkMode = ref('light');


	// columns in the legend
	const legendLabel = ref(true);
	const legendLabelSize = ref(8);
	const legendAlt = ref(true);
	const legendAgl = ref(false);
	const legendAge = ref(true);
	const legendClimb = ref(false);
	const legendName = ref(false);
	const legendClass = ref(false);
	const legendFollow = ref(false);
	const legendTrack = ref(true);
	const legendTrackerType = ref(false);
	const legendTrackerID = ref(false);
	const legendDistanceToTarget = ref(false);
	const legendBearingToTarget = ref(false);
	const legendSourceIcon = ref(false);
	const legendStartTime = ref(false);
	const legendTaskSpeed = ref(false);
	const legendTaskLeg = ref(false);
	const legendGroundSpeed = ref(false);
	const legendCallsign = ref(false);

	// get the current browser's dark mode
	if (window.matchMedia('(prefers-color-scheme: dark)').matches) darkMode.value='dark';

	const legendPanelHeight = ref(200);
	const legendPanelWidth = ref(250);
	const selectedPanelWidth = ref(350);
	const selectedPanelHeight = ref(160);

	// new itesm for layers style
	const showWelcomeScreen = ref(true);

	const useMovingAverage = ref(true); // for tracks, use the moving avergage or not
	const useTrackFilter = ref(true); // for tracks, filter out rubbish or not
	const showMountainsLayer = ref(false);

	const sideBarPage = ref('home');


	const skySightLayer = ref('zsfclclmask');
	const showSkySight = ref(null);
	const showSkySightSat = ref(false);
	const skySightForecastOpacity = ref(1);
	const skySightRegion = ref(null);
	const skySightTime = ref(null);
	const iconSize = ref(1);

	const taskOptimised = ref(false);


	function $reset() {
		//language.value = 'en';

		//console.log('reset called');
		mainLabelAircraft.value = 'rego'
		legendCallsign.value = false;
		showCallsignLabel.value = false;
		showAgeLabel.value = false;
		mapLat.value = -40.97435;
		mapLong.value = -175.409;
		mapZoom.value = 5;
		altitudeUnits.value = 'feet';
		speedUnits.value = 'knots';
		verticalUnits.value = 'knots';
		distanceUnits.value = 'kilometers';
		baseMapID.value = 'terrain';
		freeMapID.value = 'voyager';
		showLabels.value = true;
		showIcons.value = true;
		showWobbleLabels.value = true;
		maxWobbleLabels.value = 200;
		showSelectedTrack.value = true;
		sortBy.value = "label";
		sortOrder.value = "desc";
		selectedAtTop.value = true;
		showLegend.value = true;
		following.value = [];
		pauseFollowing.value = true;
		followSelected.value = true;
		onlyShowSelected.value = false;
		altitudeChartSize.value = 150;
		showChart.value = true;
		filterMaxAge.value = 30;
		filterMaxAltitude.value = null;

		showHeightLabel.value=true;
		showAGLLabel.value=false;
		showSpeedLabel.value=false;
		showVSpeedLabel.value=false;
		showThermalClimbLabel.value=false;
		shortenAircraftRegistrations.value=true;
		showNameLabel.value = false;
		showClassLabel.value = false;
		showSourceLabel.value = false;
		showTargetDistanceLabel.value = false;
		showTargetBearingLabel.value = false;
		showRegoLabel.value = false;

		showUnidentifiedLabels.value=false;
		contestID.value=null;
		showContestPanel.value=true;
		mode.value="all";
		yAxis.value="speed";
		trackLength.value=60*6;
		multiTracksLength.value=60;
		showDebug.value = false;
		showAboveGroundLevel.value = 0;
		simpleMode.value = false;

		showAirspace.value = false;
		showLocations.value = false;
		showAirports.value = true;
		showSmallAirports.value = true;
		showHelipads.value = true;
		showLaunchSites.value = true;

		showThermals.value = false;
		filterGroup.value = true;
		followGroup.value = true;
		loadAllGroup.value = true;
		filterComp.value = true;
		
		legendHideUnknown.value = true;
		legendShowThermalClimbRate.value = true;
		legendTrackerType.value = false;
		legendTrackerID.value = false;
		legendDistanceToTarget.value = false;
		legendBearingToTarget.value = false;
		legendStartTime.value = false;
		legendTaskSpeed.value = false;
		legendTaskLeg.value = false;
		legendGroundSpeed.value = false;

		showCowFarts.value=false;
		showWeatherStations.value=false;
		showThermalHeights.value = false;
		maxThermalAltitude.value = 3048;
		showWelcomeScreen.value = true;
		darkMode.value = 'light';
		if (window.matchMedia('(prefers-color-scheme: dark)').matches) darkMode.value='dark';
		legendPanelHeight.value = 300;
		selectedPanelWidth.value = 350;
		followSingle.value = null;
		showQuickButtons.value=true;
		showZones.value = false;
		showRainRadar.value = false;
		useMovingAverage.value = true;
		useTrackFilter.value = true;
		showMountainsLayer.value = false;
		sideBarPage.value = 'home';
		showSource.value = false;
		showTails.value = true;

		skySightLayer.value = null;
		showSkySight.value = false;
		showSkySightSat.value = false;
		skySightForecastOpacity.value = 1;
		skySightRegion.value = null;
		skySightTime.value = null;
		wobbleLableSize.value=14;
		wobbleLableSizeSecondLine.value=12;
		showOnGroundLabels.value=true;
		iconSize.value=1;
		taskOptimised.value=false;

		loadUrl();
	}


	function addFollowing(key) {
		//console.log('Adding follow for ' + key);

		// check for null data to remove
		for (let i=following.value.length; i>=0; i--) {
			if (following.value[i]==null) following.value.splice(i, 1);
		}

		if (typeof following.value == 'boolean' ) {
			following.value = []; // reset
		}
		if (!following.value.includes(key)) {
			following.value.push(key);
			pauseFollowing.value = false;
		}
		mapTools.goToSelected();

		//console.log(following.value);
	}
	function stopFollowing(key) {
		//console.log('Stop follow for ' + key);
		var index = following.value.indexOf(key);
		if (index!==-1) {
			following.value.splice(index, 1); // remove just the one found item
		}
		// if we delete everything, we need to ensure follow selected is ticked, otherwise following won't do anything...
		if (following.value.length==0) {
			followSelected.value = true;
		}
		mapTools.goToSelected();
		//console.log(following.value);
	}
	function stopFollowingAll() {
		following.value.splice(0); // remove all without loosing reactivity

		// if we delete everything, we need to ensure follow selected is ticked, otherwise following won't do anything...
		followSelected.value = true;
	}

	function isFollowed(key) {
		// console.log('checking if ' + key + ' is followed');
		// console.log(following.value);
		if (following.value===null) return false;
		if (!following.value?.length>0) return false;
		//console.log('got to here');
		return following.value.includes(key);
	}

	function toggleFollow() {
		pauseFollowing.value=!pauseFollowing.value; 
		if (!pauseFollowing.value) mapTools.goToSelected();
	}



	function loadUrl() {
		//console.log('loading url');

		// if we are given long separately, l=lat, otherwise l=lat,long
		if (get_url_param('g')) {
			mapLong.value = Number(get_url_param('g'));
			if (get_url_param('l')) mapLat.value = Number(get_url_param('l'));
		} else {
			if (get_url_param('l')) {
				const latLong = get_url_param('l');
				mapLat.value = latLong.split(',')[0];
				mapLong.value = latLong.split(',')[1];
			}
		}

		if (get_url_param('z')) mapZoom.value = Number(get_url_param('z'));
		// if (get_url_param('g')) this.settings.mapLong = Number(get_url_param('g'));
		// if (get_url_param('z')) this.settings.mapZoom = Number(get_url_param('z'));
		if (get_url_param('i')) if (Number(get_url_param('i'))==1) onlyShowSelected.value=true;
		//if (get_url_param('f')) if (Number(get_url_param('f'))==1) follow.value=true;
		// if (get_url_param('s')) this.parseSubSelectedString(get_url_param('s'));
		// if (get_url_param('t')) if (Number(get_url_param('t'))==1) {
			// copy the selected to also show their tracks
			// for (var i=0; i<this.subSelected.length; i++) {
			// 	this.selectedTracks.push(this.subSelected[i]);
			// }
		// 	this.selectedTracks = this.subSelected.slice();
		// 	this.zoomToSelectedAfterLoad = true;
		// }
		if (get_url_param('c')) {
			contestID.value = Number(get_url_param('c'));
			//console.log('setting contest ID from URL to ' + contestID.value);
		} else {
			// make sure we don't have a contest set if it's not in the URL
			contestID.value = null;
		}

		// this.finishedLoadingUrl = true;
		// save all the new settings
		//localStorage.setItem('settings', JSON.stringify(this.settings));
		//console.log(this.settings);
	}

	function getMapBackgroundLayerSlot(defaultLayer=null, pro=false) {
		let layer = defaultLayer;

		let currentMap = freeMapID.value;
		if (pro) currentMap =baseMapID.value;

		switch (currentMap)
		{
			case 'terrain': 
			case 'dark': 
			case 'light': 
				layer = 'land-structure-polygon';
				break;
			case 'satellite': 
				layer = 'tunnel-street-minor-low';
				break;
			case 'positron': 
			case 'darkmatter': 
			case 'voyager':
				layer = 'aeroway-runway';
				break;
			case 'osm':
				break;
		}
		// console.log('returning layer '+ layer);
		return layer;
	}


	return { 
		$reset,
		language,
		mapLat,
		mapLong,
		mapZoom,
		baseMapID,
		showMountainsLayer,
		altitudeUnits,
		speedUnits,
		verticalUnits,
		distanceUnits,
		showLabels,
		showIcons,
		showSelectedTrack,
		sortBy,
		sortOrder,
		selectedAtTop,
		showLegend,
		following,
		addFollowing,
		stopFollowing,
		stopFollowingAll,
		pauseFollowing,
		followSelected,
		followSingle,
		onlyShowSelected,
		altitudeChartSize,
		showChart,
		showInfo,
		showPicture,
		showShare,
		filterMaxAge,
		filterMaxAltitude,
		showHeightLabel,
		showAGLLabel,
		showSpeedLabel,
		showVSpeedLabel,
		showThermalClimbLabel,
		showNameLabel,
		showClassLabel,
		showSourceLabel,
		showTargetDistanceLabel,
		showTargetBearingLabel,
		showRegoLabel,

		showUnidentifiedLabels,
		shortenAircraftRegistrations,
		contestID,
		showContestPanel,
		loadUrl,
		mode,
		yAxis,
		trackLength,
		multiTracksLength,
		showDebug,
		showAboveGroundLevel,
		simpleMode,
		showAirspace,
		showLocations,
		showAirports,
		showSmallAirports,
		showHelipads,
		showLaunchSites,
		showThermals,
		filterGroup,
		followGroup,
		loadAllGroup,
		filterComp,
		legendHideUnknown,
		legendShowThermalClimbRate,
		showCowFarts,
		showWeatherStations,
		showThermalHeights,
		maxThermalAltitude,
		showWelcomeScreen,
		darkMode,
		legendPanelHeight,
		selectedPanelHeight,
		selectedPanelWidth,
		legendPanelWidth,
		legendLabel,
		legendLabelSize,
		legendAlt,
		legendAgl,
		legendAge,
		legendClimb,
		legendName,
		legendClass,
		legendFollow,
		legendTrack,
		legendTrackerType,
		legendTrackerID,
		legendDistanceToTarget,
		legendBearingToTarget,
		legendStartTime,
		legendSourceIcon,
		legendTaskSpeed,
		legendTaskLeg,
		legendGroundSpeed,
		showQuickButtons,
		loadOtherMapIconsWhileBrowsing,
		showZones,
		showRainRadar,
		showWobbleLabels,
		maxWobbleLabels,
		showSecondLine,
		useMovingAverage,
		useTrackFilter,
		sideBarPage,
		isFollowed,
		showSource,
		toggleFollow,
		showTails,
		freeMapID,
		timeUnits,
		timezoneUnits,
		skySightLayer,
		showSkySight,
		showSkySightSat,
		getMapBackgroundLayerSlot,
		skySightForecastOpacity,
		skySightRegion,
		skySightTime,
		legendCallsign,
		showCallsignLabel,
		showAgeLabel,
		mainLabelAircraft,
		wobbleLableSize,
		wobbleLableSizeSecondLine,
		showOnGroundLabels,
		iconSize,
		taskOptimised,
	}

},
{
	persist: {
		key: 'settings-v2',
		// afterRestore: (ctx) => {
		// 	console.log(`just restored '${ctx.store.$id}'`)
		// }
	},
},
);


// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(settingsStore, import.meta.hot))
}